import React from "react";
import Breadcrumbs from "components/common/breadcrumbs/Breadcrumbs";
import Page from "../components/page/Page";
import { moduleMap } from "./page";

const EventTemplate = ({ pageContext }) => {
  const routes = [
    {
      label: "Events",
      route: "/events",
    },
    {
      label: pageContext.breadcrumbTitle,
      route: pageContext.url,
    },
  ];
  return (
    <Page headerOverlapped={false} headerTextColorInverse={false}>
      <Breadcrumbs list={routes} />
      {pageContext.modules.map((module, i) => {
        const Module = moduleMap[module?.sys?.contentType?.sys?.id];

        if (!Module) {
          return (
            <span key={i}>Module {module?.sys?.contentType?.sys?.id} is not supported yet</span>
          );
        }

        return (
          <Module
            key={i}
            {...module}
            id={module.id}
            noBottomMargin={i === pageContext.modules.length - 1}
          />
        );
      })}
    </Page>
  );
};

export default EventTemplate;
