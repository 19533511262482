import React, { HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { Button1, Button2 } from "components/common/typography/Typography";
import { Link as A } from "components/common/link";
import { useLocation } from "@reach/router";
import { Icon } from "components/common/icon";
import { useTheme } from "styled-components";

interface IProps extends HTMLAttributes<"div"> {
  list: Array<{ label: string; route: string }>;
}

const Breadcrumbs: React.FC<IProps> = ({ list, ...props }) => {
  const location = useLocation();
  const theme = useTheme();

  return (
    <div className="px-xxxl lg:px-xl md:px-md">
      <div className="flex gap-md items-center">
        {list.map((l, i) => (
          <div className="flex gap-md items-center" key={l.route}>
            <A
              isSubtle
              color={theme.colors.black}
              href={l.route}
              key={l.route}
              forceHover={location?.pathname === l.route}>
              {location?.pathname === l.route ? (
                <Button1>{l.label}</Button1>
              ) : (
                <Button2>{l.label}</Button2>
              )}
            </A>
            {i < list.length - 1 && <Icon name={"ChevronRight"} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
